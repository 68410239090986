import React, {useState, useEffect, FormEvent} from 'react';
import { client as apiClient } from './utils/api-client';
import { useAsync } from './utils/use-async';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

enum ArrType {
    Sonarr = "Sonarr (TV)",
    SonarrAnime = "Sonarr (Anime)",
    Radarr = "Radarr",
}

export default function Admin() {
    return (
        <div>
            <h3>Admin</h3>
            <Tabs defaultActiveKey="generalSettings">
                <Tab eventKey="generalSettings" title="General">
                    <GeneralSettingsComponent />
                </Tab>
                <Tab eventKey="users" title="Users">
                    <Users />
                </Tab>
                <Tab eventKey="sonarr" title="Sonarr (TV)">
                    <ArrSettingsComponent arrType={ArrType.Sonarr} />
                </Tab>
                <Tab eventKey="sonarr_anime" title="Sonarr (Anime)">
                    <ArrSettingsComponent arrType={ArrType.SonarrAnime} />
                </Tab>
                <Tab eventKey="radarr" title="Radarr">
                    <ArrSettingsComponent arrType={ArrType.Radarr} />
                </Tab>
            </Tabs>
        </div>
    )
}

const ArrTypeToJson = (a: ArrType) => {
    switch (a) {
        case ArrType.Sonarr:
            return "sonarr";
        case ArrType.SonarrAnime:
            return "sonarr_anime";
        case ArrType.Radarr:
            return "radarr";
    }
}

interface ApiResponse {
    success: boolean,
    users: User[],
    settings: Settings,
    servers: PlexServer[]
}

interface TestResponse {
    success: boolean,
    root_folders: string[],
    quality_profiles: {id: number, name: string}[]
}

interface User {
    id: number,
    username: string,
    display_name: string,
    email: string,
    is_admin: boolean
}

interface Settings {
    general: GeneralSettings,
    sonarr: SonarrSettings,
    sonarr_anime: SonarrSettings,
    radarr: RadarrSettings,
}

interface GeneralSettings {
    plex_server_id?: string,
    moviedb_api_key?: string,
    discord_webhook?: string
}

interface ArrSettings {
    api_key?: string,
    base_url?: string,
    root_folder?: string,
    quality?: string
}

interface SonarrSettings extends ArrSettings {
    search_on_add?: boolean
}

interface RadarrSettings extends ArrSettings {
    minimum_availability?: string
}

function Users() {
    const [users, setUsers] = useState<User[]>([]);
    const [editUser, setEditUser] = useState<User>({} as User);
    const [show, setShow] = useState(false);
    const { run } = useAsync();

    useEffect(() => {
        setUsers([])
        run(
            apiClient('admin/users').then((data: ApiResponse) => setUsers(data.users))
        );
    }, []);

    const handleShow = (user: User) => {
        setEditUser(user);
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setEditUser({} as User);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let trueValue = name === "is_admin" ? e.target.checked : value;
        setEditUser({ ...editUser, [name]: trueValue });
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        run(
            apiClient('admin/users/update', { body: {
                "id": editUser?.id,
                "display_name": editUser?.display_name,
                "is_admin":editUser?.is_admin
            } })
                .then((data) => {
                    if (data.success) {
                        toast("Saved user", { type: "success" });
                        apiClient('admin/users').then((data: ApiResponse) => setUsers(data.users))
                        handleClose();
                    } else {
                        toast("Failed to save user", { type: "error" });
                    }
                })
                .catch(() => {
                    toast("Failed to save user", { type: "error" });
                }));
    }

    return (
        <>
            <Table striped bordered hover style={{ paddingTop: 25 }} variant={"dark"}>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Display Name</th>
                        <th>Email</th>
                        <th>Admin</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((u) => {
                        return (
                            <tr key={u.username}>
                                <td>{u.username}</td>
                                <td>{u.display_name}</td>
                                <td>{u.email}</td>
                                <td><Form.Check type='checkbox' checked={u.is_admin} readOnly /></td>
                                <td><Button variant={"primary"} size={"sm"} onClick={() => handleShow(u)}>Edit</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            <Modal show={show} onHide={handleClose} data-bs-theme={"dark"}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User: {editUser?.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="display_name">
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="display_name"
                                placeholder="Steve Johnson"
                                autoFocus
                                defaultValue={editUser?.display_name}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="is_admin">
                            <Form.Label>Admin</Form.Label>
                            <Form.Check
                                type="checkbox"
                                name="is_admin"
                                defaultChecked={editUser?.is_admin}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant={"primary"} onClick={handleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

interface PlexServer {
    id: string,
    name: string,
    address: string
}

function GeneralSettingsComponent() {
    const [settings, setSettings] = useState<GeneralSettings>({});
    const [servers, setServers] = useState<PlexServer[]>([]);
    const [changed, setChanged] = useState(false);
    const { run } = useAsync();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => {
        const { name, value } = e.target;
        setSettings({ ...settings, [name]: value });
        setChanged(true);
    }

    useEffect(() => {
        apiClient('admin/settings').then((data: ApiResponse) => setSettings(data.settings.general))
    }, [])

    useEffect(() => {
        apiClient('admin/servers').then((data: ApiResponse) => setServers(data.servers));
    }, []);

    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        run(
            apiClient('admin/settings', { body: {"general": settings} })
                .then((data) => {
                    if (data.success) {
                        toast("saved", { type: "success" });
                    } else {
                        toast("failed to save", { type: "error" });
                    }
                })
                .catch(() => {
                    toast("failed to save", { type: "error" });
                }));
    }

    return (
        <Form onSubmit={handleSubmit} style={{ paddingTop: 10 }}>
            <Form.Group as={Row} controlId={'plex_server_id'}>
                <Form.Label column sm={2}>Plex Server</Form.Label>
                <Col sm={10}>
                    <Form.Select name={'plex_server_id'} onChange={handleInputChange}
                                  value={settings.plex_server_id}>
                        {servers.map((s) => {
                            return (
                                <>
                                    <option value={s.id} key={s.id}>{s.name} - {s.address}</option>
                                </>
                            )
                        })}
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'moviedb_api_key'}>
                <Form.Label column sm={2}>MovieDB API Key</Form.Label>
                <Col sm={10}>
                    <Form.Control type="text" name="moviedb_api_key" onChange={handleInputChange}
                                  value={settings.moviedb_api_key}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'discord_webhook'}>
                <Form.Label column sm={2}>Discord Webhook URL</Form.Label>
                <Col sm={10}>
                    <Form.Control type="text" name="discord_webhook" onChange={handleInputChange}
                                  value={settings.discord_webhook}/>
                </Col>
            </Form.Group>
            <Button variant={"warning"} type="submit" disabled={!changed}>
                Save
            </Button>
        </Form>
    )
}

function ArrSettingsComponent({arrType}: {arrType: ArrType}) {
    const [settings, setSettings] = useState<ArrSettings>({});
    const [folders, setFolders] = useState<string[]>([]);
    const [qualities, setQualities] = useState<{id: number, name: string}[]>([]);
    const [changed, setChanged] = useState(false);
    const { run } = useAsync();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => {
        const { name, value } = e.target;
        let checked, isCheckbox = false;
        if (e.target.type === "checkbox") {
            isCheckbox = true;
            checked = (e.target as HTMLInputElement).checked;
        }
        setSettings({ ...settings, [name]: isCheckbox ? checked : value });
        setChanged(true);
    }

    useEffect(() => {
        apiClient('admin/settings').then((data: ApiResponse) => {
            switch (arrType) {
                case ArrType.Sonarr:
                    setSettings(data.settings.sonarr);
                    testSettings(data.settings.sonarr);
                    break;
                case ArrType.SonarrAnime:
                    setSettings(data.settings.sonarr_anime);
                    testSettings(data.settings.sonarr_anime);
                    break;
                case ArrType.Radarr:
                    setSettings(data.settings.radarr);
                    testSettings(data.settings.radarr);
                    break;
            }
        })
    }, [])

    function testSettings(test_settings: ArrSettings) {
        apiClient('admin/test', { body: {[ArrTypeToJson(arrType)]: test_settings} })
                .then((data: TestResponse) => {
                    if (data.success) {
                        toast(`${arrType} connection successful`, { type: "success" });
                        setFolders(data.root_folders);
                        setQualities(data.quality_profiles);
                    } else {
                        toast(`${arrType} connection failed`, { type: "error" });
                        setFolders([]);
                        setQualities([]);
                    }
                })
                .catch(() => {
                    toast(`${arrType} connection failed`, { type: "error" });
                    setFolders([]);
                    setQualities([]);
                });
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();
        run(
            apiClient('admin/settings', { body: {[ArrTypeToJson(arrType)]: settings} })
                .then((data) => {
                    if (data.success) {
                        toast(`${arrType} settings saved`, { type: "success" });
                    } else {
                        toast(`Failed to save ${arrType} settings`, { type: "error" });
                    }
                })
                .catch(() => {
                    toast(`Failed to save ${arrType} settings`, { type: "error" });
                }));
    }

    return (
        <Form onSubmit={handleSubmit} style={{ paddingTop: 10 }}>
            <Form.Group as={Row} controlId={'base_url'}>
                <Form.Label column sm={2}>Base URL</Form.Label>
                <Col sm={10}>
                    <Form.Control type="text" name="base_url" onChange={handleInputChange}
                                  value={settings.base_url}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'api_key'}>
                <Form.Label column sm={2}>API Key</Form.Label>
                <Col sm={10}>
                    <Form.Control type="text" name="api_key" onChange={handleInputChange}
                                  value={settings.api_key}/>
                </Col>
            </Form.Group>
            <Button variant={"warning"} type="button" onClick={() => testSettings(settings)}>
                Test/Update
            </Button>
            <Form.Group as={Row} controlId={'root_folder'}>
                <Form.Label column sm={2}>Root Folder</Form.Label>
                <Col sm={10}>
                    <Form.Select name={'root_folder'} onChange={handleInputChange}
                                  value={settings.root_folder}>
                        {folders.map((f) => {
                            return (
                                <>
                                    <option value={f} key={f}>{f}</option>
                                </>
                            )
                        })}
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId={'quality'}>
                <Form.Label column sm={2}>Quality</Form.Label>
                <Col sm={10}>
                    <Form.Select name={'quality'} onChange={handleInputChange}
                                  value={settings.quality}>
                        {qualities.map((f) => {
                            return (
                                <>
                                    <option value={f.id} key={f.id}>{f.name}</option>
                                </>
                            )
                        })}
                    </Form.Select>
                </Col>
            </Form.Group>
            {arrType === ArrType.Radarr ?
                <Form.Group as={Row} controlId={'minimum_availability'}>
                    <Form.Label column sm={2}>Minimum Availability</Form.Label>
                    <Col sm={10}>
                        <Form.Select name={'minimum_availability'} onChange={handleInputChange}
                                      value={(settings as RadarrSettings).minimum_availability}>
                            <option value={"announced"}>Announced</option>
                            <option value={"inCinemas"}>In Cinemas</option>
                            <option value={"released"}>Release</option>
                        </Form.Select>
                    </Col>
                </Form.Group> :
                <Form.Group as={Row} controlId={'search_on_add'}>
                    <Form.Label column sm={2}>Search on Add</Form.Label>
                    <Col sm={10}>
                        <Form.Check type={'switch'} name={'search_on_add'} defaultChecked={(settings as SonarrSettings).search_on_add} onChange={handleInputChange} />
                    </Col>
                </Form.Group>}
            <Button variant={"warning"} type="submit" disabled={!changed}>
                Save
            </Button>
        </Form>
    )
}
